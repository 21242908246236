body {
  margin: 0;
}

.header-fixed {

  width: 100%;
  position: fixed;
  background-color: #ffffff;
  height: 70px;
  box-shadow: 1px 1px 5px 3px #ccc;
  z-Index: 1000;
}

.header-fixed-placeholder {

  height: 70px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 3px #ccc;
  z-index: 1000;
}

.order-taxi-button {
  margin-left: 240px !important;
}

.navbar-menu {
  padding-top: 6px;
  padding: 12px;
  cursor: pointer;
}

.navbar-menu-line {
  display: block;
  background-color: #FFC200;
  height: 2px;
  width: 18px;
  margin-bottom: 3px;
  transition-duration: 250ms;
}

.navbar-menu-navbar-visible .navbar-menu-line-1 {
  transform: translateY(5px) rotate(45deg);
}

.navbar-menu-navbar-visible .navbar-menu-line-2 {
  opacity: 0;
}

.navbar-menu-navbar-visible .navbar-menu-line-3 {
  transform: translateY(-5px) rotate(-45deg);
}

.navbar {
  width: 288px;
  background-color: white;
  height: 100%;
  position: fixed;
  box-shadow: 0 0 5px grey;
  z-index: 1;
  transition-duration: 250ms;
}

.navbar-hidden {
  transform: translateX(-105%);
  opacity: 0;
}

.navbar-visible {
  transition-property: transform, opacity;
  opacity: 1;
}

.start-image {
  max-width: 100%;
  height: 'auto';
  margin-bottom: 42px;
  margin-top: 48px !important;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
}

.start-contact-data {
  width: 100% !important;
  justify-content: space-between;
}

.content {
  margin: 0;
}

@media only screen and (max-width: 800px) {

  .start-image {
    margin-bottom: 24px;
  }

  .start-contact-data>div {
    display: block;
  }

  .start-contact-data-entry {
    margin: 12px 0px;
  }
}

@media only screen and (max-width: 699px) {

  .header-left-menu-bar {
    padding-left: 20px;
  }

  #order-taxi-form-stack {
    display: none;
    background-color: tomato;
  }

  .call-now-button {
    margin-right: 0px;
  }

  .start-contact-data {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 529px) {

  .header-right-call-cta {
    display: none;
  }

  .order-taxi-button {
    display: none !important;
  }

  .header-left-menu-bar-title {
    margin-left: 10px !important;
  }
}